import Layout from "../components/layout"
import * as React from "react"
import { Helmet } from "react-helmet"
import { BlogListComponent } from "../components/blog-page/posts-list/posts-list"
import { graphql } from "gatsby"

const BlogPage = ({ data, pageContext }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KalBytes - Case Studies & stories</title>
        <link rel="canonical" href="https://www.kalbytes.de/" />
      </Helmet>

      <div className="blog-page page">
        <h1>Case studies and Stories</h1>
        <BlogListComponent
          posts={data.allContentfulBlog.nodes}
        ></BlogListComponent>
      </div>
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogQuery($locale: String) {
    allContentfulBlog(
      sort: { fields: publishDate, order: DESC }
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        node_locale
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        description
        type
        image {
          file {
            url
          }
          title
        }

        body {
          raw
        }
      }
    }
  }
`

export default BlogPage
