import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import "./posts-list.scss"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { Link } from "gatsby-plugin-react-intl"
const readingTime = require("reading-time/lib/reading-time")

export const BlogListComponent = props => {
  const { posts } = props
  const [activeMenu, setActiveMenu] = useState("all")
  const location = useLocation()
  const [displayedPosts, setDisplayedPosts] = useState(posts)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const menuName = searchParams.get("menu")
    onSetActiveMenu(menuName || "all")
  }, [location])

  const onSetActiveMenu = menuName => {
    setActiveMenu(menuName)

    if (menuName === "all") {
      setDisplayedPosts(posts)
      return
    }
    const postsToDisplay = posts.filter(post => post.type === menuName)
    setDisplayedPosts(postsToDisplay)
  }

  const getReadingTime = post => {
    const plainTextBody = documentToPlainTextString(JSON.parse(post.body.raw))
    const readingTimeStats = readingTime(plainTextBody)
    return readingTimeStats.text
  }

  return (
    <div className="blog-posts-list">
      <div className="blog-navigation">
        <ul>
          <Link to={`/case-studies-and-stories?menu=all`}>
            <li
              className={activeMenu === "all" ? "li-active" : ""}
              onClick={() => onSetActiveMenu("all")}
            >
              All
            </li>
          </Link>

          <Link to={`/case-studies-and-stories?menu=case`}>
            <li
              className={activeMenu === "case" ? "li-active" : ""}
              onClick={() => onSetActiveMenu("case")}
            >
              Case Studies
            </li>
          </Link>

          <Link to={`/case-studies-and-stories?menu=blog`}>
            <li
              className={activeMenu === "blog" ? "li-active" : ""}
              onClick={() => onSetActiveMenu("blog")}
            >
              Blog Posts
            </li>
          </Link>
        </ul>
      </div>

      <div className="blog-post-cards">
        {displayedPosts &&
          displayedPosts.map((post, index) => (
            <Link to={`/case-studies-and-stories/${post.slug}`} title={post.title}>
            <div

              key={index}
              className="blog-post-card"
            >
              <div className="left">
                <img src={post.image.file.url} alt={post.image.title}></img>
              </div>
              <div className="right">
                <div className="title">
                  <span className="title-publish">{post.publishDate}</span>
                  <h2>{post.title}</h2>
                </div>
                <div className="description">
                  <p>{post.description}</p>
                </div>

                <div className="reading-time">
                  <span>{getReadingTime(post)}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                  >
                    <path d="m702 755-43-42 106-106H120v-60h646L660 441l42-42 178 178-178 178Z" />
                  </svg>
                </div>
              </div>
            </div>
            </Link>
          ))}
      </div>
    </div>
  )
}
